import React from 'react';
import Piece0 from "./images/1 - Lurkin' Turnips.jpg";
import Piece1 from "./images/2 - Garden Martians.jpg";
import Piece2 from "./images/3 - Cabbages.jpeg";
import Piece3 from "./images/4 - Cosmic Lotus.jpg";
import Piece4 from "./images/5 - Meditations.jpeg";
import Piece5 from "./images/6 - Charcoal Portrait.jpeg";
import Piece6 from "./images/7 - A Walk.jpg";
import Piece7 from "./images/8 - Ghost Flowers.jpeg";
import Piece8 from "./images/9 - Sorry Sam.png";
import Piece9 from "./images/10 - Decaying Hexagons.png";
import Piece10 from "./images/11 - Midnight Electric.png";
import Piece11 from "./images/12 - Elephant Castle.jpg";
import Piece12 from "./images/13 - Tentacle Summoning.png";
import Piece13 from "./images/14 - Birth of Cephalobaby.jpeg";
import Piece14 from "./images/15 - Pomegranate Szn.jpg";
import Piece15 from "./images/16 - Granada.JPG";
import Piece16 from "./images/17 - Mountain.jpeg";
import Piece17 from "./images/18 - I Heart PNW.jpg";
import Piece18 from "./images/19 - Splashin.jpg";
import Piece19 from "./images/20 - The Hill Waves.jpg";
import './App.css';


var pieces = [
  Piece9,
  Piece10,
  Piece11,
  Piece12,
  Piece13,
  Piece14,
  Piece15,
  Piece16,
  Piece17,
  Piece18,
  Piece19,
  Piece0,
  Piece1,
  Piece2,
  Piece3,
  Piece4,
  Piece5,
  Piece6,
  Piece7,
  Piece8
]

var captions = [
  {
    title: "Decaying Hexagons",
    name: "Sharia Malu",
    alt: "Two towers of hexagons lean together next to a block of morbid text"
  },
  {
    title: "Midnight Electric",
    name: "Sam Summer",
    alt: "Two stark white telephone poles pierce an inky night sky"
  },
  {
    title: "Elephant Castle",
    name: "Rahul Narain",
    alt: "A spiky white tower made of legos"
  },
  {
    title: "Tentacle Summoning",
    name: "Noah Zweben",
    alt: "A cackling witch looms over a smoking cauldron from which sinister tentacles emerge"
  },
  {
    title: "Birth of Cephalobaby",
    name: "Philina Yee",
    alt: "An octopus made of geometric pieces emerges from a bubble"
  },
  {
    title: "Pomegranate Szn",
    name: "Gabby Warshay",
    alt: "A tree laden with construction paper leaves and pomegranates"
  },
  {
    title: "Granada",
    name: "Nick Andresen",
    alt: "A vibrant circle with fractal, geometric patterns"
  },
  {
    title: "Mountain",
    name: "Rahul Basu",
    alt: "A minimalist mountain rises against a starry night sky, surrounded by pentacle-like geometric patterns"
  },
  {
    title: "I Heart PNW",
    name: "Victoria Chang",
    alt: "A blurry, zoomed-in picture of Mount Rainier is littered with bright red hashtags"
  },
  {
    title: "Splashin",
    name: "Léa Kichler",
    alt: "Three jovial giants splash through a pond as if it were a puddle"
  },
  {
    title: "The Hill Waves",
    name: "Sybil Lui",
    alt: "A dark pond backed by reeds and clouds is superimposed on a white grid"
  },
  {
    title: "Lurkin’ Turnips",
    name: "Andrew Coronato",
    alt: "A cabal of smug and mischevious turnips peer out from behind construction paper grass"
  },
  {
    title: "Garden Martians",
    name: "Amy Summer",
    alt: "An alien UFO uses a tractor beam to abduct a juicy kohlrabi plant"
  },
  {
    title: "Cabbages",
    name: "Ella Jakubowicz",
    alt: "Two cartoon characters hang out on a pile of lackluster cabbages"
  },
  {
    title: "Cosmic Lotus",
    name: "Emma Vales",
    alt: "A woman spreads her arms, surrounded by planets, reaching hands, and written poetry"
  },
  {
    title: "Meditations",
    name: "Kayla Miron",
    alt: "An embroidered woman, viewed from behind, nude and sitting cross-legged"
  },
  {
    title: "Portrait",
    name: "Laura Wenmaekers",
    alt: "A side view of a nude woman looking pensively into the distance"
  },
  {
    title: "A Walk in Progress",
    name: "Lily Vanderbloemen",
    alt: "A grid of typical neighborhood scenery, including flowers, a BLM sign, and some garden flamingos"
  },
  {
    title: "Ghost Flowers",
    name: "Jared Greene",
    alt: "A hexagonal table made of clear resin with suspended flowers"
  },
  {
    title: "Sorry Sam",
    name: "Jaron Ma",
    alt: "The blurry reflection of a photographer shows in a hexagon surrounded by computer code"
  }
]

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slide: 0,
      xStart: 0,
      xEnd: 0
    };
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchCancel = this.handleTouchCancel.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchend', this.handleTouchEnd, false);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    pieces.forEach((piece) => {
      const img = new Image();
      img.src = piece.fileName;
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
    document.removeEventListener('touchstart', this.handleTouchStart, false);
    document.removeEventListener('touchend', this.handleTouchEnd, false);
  }

  handleTouchStart(event) {
    var xStart = event.touches[0].clientX;
    this.setState({
      xStart: xStart
    })
  }

  handleTouchEnd(event) {
    var xEnd = event.changedTouches[0].clientX;
    this.setState({
      xEnd: xEnd
    })
    if (this.state.xStart - this.state.xEnd < -100) {
      this.handleBackClick();
    } else if (this.state.xStart - this.state.xEnd > 100) {
      this.handleNextClick();
    }
  }

  handleTouchCancel() {
    this.setState({
      xStart: 0,
      xEnd: 0
    })
  }

  handleKeyDown(event) {
    if (event.keyCode === 37) {
      this.handleBackClick();
    }
    if (event.keyCode === 39) {
      this.handleNextClick();
    }
  }

  handleBackClick() {
    this.setState({
      slide: (this.state.slide === 0 ? 19 : this.state.slide - 1)
    })
  }

  handleNextClick() {
    this.setState({
      slide: (this.state.slide === 19 ? 0 : this.state.slide + 1)
    })
  }

  render() {
    return (
      <div
        className="app-wrapper"
        onTouchCancel={this.handleTouchCancel}
      >
        <div className="button-bg back-button-bg"></div>
        <div className="button-bg next-button-bg"></div>
        <button
          className="back-button"
          onClick={this.handleBackClick}
        >
          ←
        </button>
        <button
          className="next-button"
          onClick={this.handleNextClick}
        >
          →
        </button>
        <div className="slide-outer-wrapper">
          <div className="slide-inner-wrapper">
            <img
              alt={captions[this.state.slide].alt}
              src={pieces[this.state.slide]}
            />
            <div className="slide-text-wrapper">
              <h2>
                {captions[this.state.slide].title}
                <span className="slide-number">{this.state.slide + 1}/{captions.length}</span>
              </h2>
              <h3>{captions[this.state.slide].name}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
